import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "link" ]

  initialize() {
    const targets = this.linkTargets

    targets.forEach(target => {
      // Remove the value that allows stimulus to target this
      // from data-target because it might interfere with
      // something like a Boostrap modal's target.
      // This also fixes any issues with the string that remains.
      target.dataset.target = target.dataset.target.replace(/prevent-new-tab\.link/, "").replace(/  /, " ").trim()

      target.addEventListener("auxclick", function(e) {
        e.preventDefault()
      })

      target.addEventListener("contextmenu", function(e) {
        e.preventDefault()
      })
    })
  }
}
