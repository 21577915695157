import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "name" ]

  // On new element, the inputs should have a red border, however the current version of Stimulus doesn't support this event.

  nameInput(e) {
    const names = this.nameTargets
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9._-]*$/
    const otherNames = names.filter(el => el != e.target).map(el => el.value)

    // The name needs to match the regex and not be a duplicate.
    if (e.target.value.match(regex) && !otherNames.includes(e.target.value)) {
      e.target.style.borderColor = ""
    } else {
      e.target.style.borderColor = "red"
    }
  }

  valueInput(e) {
    if (e.target.value != "") {
      e.target.style.borderColor = ""
    } else {
      e.target.style.borderColor = "red"
    }
  }
}
