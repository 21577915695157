import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "btn" ]

  initialize() {
    const btn = this.btnTarget

    this.changeBtndisplay(window.pageYOffset, btn)

    // On scroll
    window.addEventListener("scroll", () => {
      this.changeBtndisplay(window.pageYOffset, btn)
    });
  }

  changeBtndisplay(y, btn) {
    if (y < 1500) {
      btn.style.display = "none"
    } else {
      btn.style.display = "block"
    }
  }

  scroll() {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
  }
}
