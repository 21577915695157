import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "roomNumber", "contactName", "contactPhone", "contactDeviceMacAddress", "escalateHint", "escalateButton", "serverSelect" ]

  initialize() {
    this.validate()
    this.maskContactPhone()
  }

  validate() {
    const roomNumber = this.roomNumberTarget
    const name = this.contactNameTarget
    const phoneNumber = this.contactPhoneTarget
    const deviceMac = this.contactDeviceMacAddressTarget
    const escalateHint = this.escalateHintTarget
    const escalateButton = this.escalateButtonTarget
    const serverSelect = this.serverSelectTarget

    // Keys will be used for the error message
    const missing = this.missingValues({
      "room number": roomNumber.value,
      "name": name.value,
      "phone number": phoneNumber.value,
      "device MAC address": deviceMac.value,
      "server": serverSelect.value
    })

    if (missing.length == 0) {
      // Escalation is valid
      this.showEscalation(escalateButton, escalateHint)
      return
    }

    this.hideEscalation(escalateButton, escalateHint, missing)
  }

  maskContactPhone() {
    const phoneInput = this.contactPhoneTarget

    $(phoneInput).change(function() {
      const val = this.value

      if (val.length == 10) {
        this.value = val.slice(0,3) + "-" + val.slice(3,6) + "-" + val.slice(6)
      }
    })
  }

  // Helper functions

  showEscalation(escalateButton, escalateHint) {
    escalateButton.classList.remove("hidden")
    escalateHint.textContent = "Call will be closed and a ticket will be sent to triage" // TODO: Find a way to make this work in french too...
  }

  hideEscalation(escalateButton, escalateHint, missing) {
    escalateButton.classList.add("hidden")

    let err = "A "

    for (let i = 0; i < missing.length; i++) {
      err += missing[i]

      if (i != missing.length - 1)
        err += ", "
    }

    err += " is required for escalation"
    escalateHint.textContent = err
  }

  missingValues(args) {
    const missing = []

    for (const [key, value] of Object.entries(args)) {
      if (value == "" || value == undefined) {
        missing.push(key)
      }
    }

    return missing
  }
}
