import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "element", "siteSelect", "serverSelect", "serverLabel", "callId", "externalLinkServer", "serverSection" ]

  connect() {
    // Little hack to receive the change event from select2
    $(this.siteSelectTarget).on('select2:select', function(e) {
      let event = new Event('change', { bubbles: true })
      e.target.dispatchEvent(event)
    })

    $(this.serverSelectTarget).on('select2:select', function(e) {
      let event = new Event('change', { bubbles: true })
      e.target.dispatchEvent(event)
    })

    // If the call has a site/server, preselect them
    const siteIdData = $(this.siteSelectTarget).data("siteId")
    const serverIdData = $(this.serverSelectTarget).data("serverId")
    const serverSection = this.serverSectionTarget

    if (siteIdData != undefined) {
      $(this.siteSelectTarget).val(siteIdData).trigger("change")
      this.loadServers(siteIdData, false)

      if (serverIdData != undefined) {
        $(this.serverSelectTarget).val(serverIdData).trigger("change")
        this.externalLinkServerTarget.classList.remove("hidden")
      }
    } else {
      // No site ID is present, therefore hide the server select
      serverSection.classList.add("hidden")
    }
  }

  siteSelect() {
    const siteId = this.siteSelectTarget.value
    const serverSection = this.serverSectionTarget

    // Get list of servers for selected site
    this.loadServers(siteId, true)
    serverSection.classList.remove("hidden")
  }

  serverSelect() {
    const serverSelect = this.serverSelectTarget
    const callId = $(this.callIdTarget).data("callId")
    const externalLinkServer = this.externalLinkServerTarget

    this.saveServer(callId, serverSelect.value)
    externalLinkServer.classList.remove("hidden")
    externalLinkServer.href = "/vista/servers/" + serverSelect.value // Update external link to server
  }

  // Helper functions

  loadServers(siteId, saveServer = false) {
    const self = this // To access other functions
    const serverLabel = this.serverLabelTarget
    const serverSelect = this.serverSelectTarget
    const callId = $(this.callIdTarget).data("callId")
    const externalLinkServer = this.externalLinkServerTarget

    $.ajax({
      dataType: "json",
      type: "get",
      async: false,
      url: "/vista/sites/" + siteId + "/load_servers",
      success: function(data) {
        serverSelect.innerHTML = ""

        // Update server label
        let label = serverLabel.textContent.split(" - ")[0]
        serverLabel.textContent = label + " - (" + data.length + ")"

        if (data.length > 1) {
          self.handle_multiple_servers(serverSelect)
          externalLinkServer.classList.add("hidden")
        }
        else if (data.length == 1) {
          if (saveServer) {
            // Only save server if a user changed the input.
            self.saveServer(callId, data[0][0])
          }

          externalLinkServer.classList.remove("hidden")
          externalLinkServer.href = "/vista/servers/" + data[0][0]
        }

        // Add options in server select
        for (let i = 0; i < data.length; i++) {
          serverSelect.innerHTML += "<option value=\"" + data[i][0] + "\">" + self.computedName(data[i][1], data[i][2]) + "</option>"
        }
      }
    });
  }

  computedName(name, hostname) {
    if (name == "" || name == undefined) {
      return hostname
    }

    return name
  }

  handle_multiple_servers(serverSelect) {
    // Insert a "select a server" option
    serverSelect.innerHTML += "<option value=\"\">Select a product</option>"
  }

  saveServer(callId, serverId) {
    $.ajax({
      type: "POST",
      url: "/admin/calls/" + callId + "/save_server",
      data: {server_id: serverId}
    });
  }
}
