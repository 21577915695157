import consumer from "./consumer"

if ($("#meta-task").length > 0) {
  var class_id = $("#meta-task").attr("class_id")
  consumer.subscriptions.create(
    {
      channel: "TaskCommentNotificationsChannel",
      task_id: class_id
    },{
      received: function(data) {
        $.ajax({
          url: `/admin/tasks/${class_id}/comments/${data}/render_comment`,
          type: "get",
          success: function(data) { data }
        })
      }
    }
  );
}
