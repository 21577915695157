import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "organizationId", "organizationName", "networkId", "networkName", "networkHashHint", "networkHash" ]

  loadOrganizations() {
    // Add a spinner while we're fetching organizations from Meraki
    $("#meraki-org-spinner").show()

    const self = this
    const serverId = this.element.dataset.serverId
    const organizationInput = $(this.organizationIdTarget)
    const organizationValue = organizationInput[0].value
    const networkIdInput = this.networkIdTarget
    const networkNameInput = this.networkNameTarget
    const networkHashInput = this.networkHashTarget
    const networkHashHint = this.networkHashHintTarget

    $.ajax({
      url: `/vista/servers/${serverId}/wireless_infrastructure/load_meraki_organizations`,
      dataType: "json",
      type: "GET",
      success: function(data) {
        const organizations = data
        const firstOption = organizationInput[0][0]
        organizationInput.empty()

        // Insert blank option, then add all organizations. Select the option with the current value.
        organizationInput.append(firstOption)
        $.each(organizations, function(_, value) {
          organizationInput.append($("<option></option>").attr("value", value[1]).text(value[0]))
        })

        // Transform into a select2 field, automatically open it.
        organizationInput.select2({
          theme: "bootstrap",
          width: "100%"
        })
        organizationInput.val(organizationValue).trigger("change")
        organizationInput.off("select2:select")
        organizationInput.select2("open")
        self.organizationNameTarget.setAttribute("value", organizationInput[0].options[organizationInput[0].selectedIndex].text)

        // Hide spinner
        $("#meraki-org-spinner").hide()

        // Start loading networks if an org is selected.
        if (organizationValue) {
          self.loadNetworks()
        }

        // Listen for a change of org in order to load networks
        organizationInput.on("select2:select", function(e) {
          // Change the hidden input value
          const selected = e.target.value

          // Clear the network name and hash
          networkNameInput.removeAttribute("value")
          networkHashInput.removeAttribute("value")
          networkHashHint.textContent = "N/A"

          if (selected) {
            self.organizationNameTarget.setAttribute("value", organizations.find(n => n[1] === selected)[0])
            self.loadNetworks()
          } else {
            self.organizationNameTarget.removeAttribute("value")
            // Clear all values from networks except the blank value
            $(networkIdInput).find("option").not(":first").remove()
          }
        })
      }
    })
  }

  loadNetworks() {
    const serverId = this.element.dataset.serverId
    const organizationValue = this.organizationIdTarget.value
    const networkIdInput = $(this.networkIdTarget)
    const networkNameInput = this.networkNameTarget
    const networkHashInput = this.networkHashTarget
    const networkHashHint = this.networkHashHintTarget

    const initialNetworkValue = networkIdInput[0].value

    // Add a spinner while we're fetching networks from Meraki
    $("#meraki-network-spinner").show()

    // Get list of networks and handle the input
    $.ajax({
      url: `/vista/servers/${serverId}/wireless_infrastructure/load_meraki_networks`,
      dataType: "json",
      data: { organization: organizationValue },
      type: "GET",
      success: function(data) {
        const networks = data
        const firstOption = networkIdInput[0][0]
        networkIdInput.empty()

        // Insert blank option, then add all networks. Select the option with the current value.
        networkIdInput.append(firstOption)
        $.each(networks, function(_, value) {
          networkIdInput.append($("<option></option>").attr("id", value[1]).attr("value", value[1]).text(value[0]))
        })

        // Transform into a select2 field
        networkIdInput.select2({
          theme: "bootstrap",
          width: "100%"
        })

        // Preselect values if the preselected network exists in the list
        if (networks && networks.length > 0 && networks.filter(network => network[1] == initialNetworkValue).length > 0) {
          networkIdInput.val(initialNetworkValue).trigger("change")
        } else {
          // Otherwise select the blank input
          networkIdInput.val("").trigger("change")
        }

        const selectedNetwork = networkIdInput[0].options.namedItem(initialNetworkValue)
        if (selectedNetwork) {
          networkNameInput.setAttribute("value", selectedNetwork.text)
        }

        networkIdInput.off("select2:select")

        // Hide spinner
        $("#meraki-network-spinner").hide()

        // On select2:select
        networkIdInput.on("select2:select", function(e) {
          const selected = networks.find(n => n[1] === e.target.value)

          if (!selected) {
            networkNameInput.removeAttribute("value")
            networkHashInput.removeAttribute("value")
            networkHashHint.textContent = "N/A"
            return
          }

          const url = selected[2]
          const name = selected[0]

          // Change hidden input name
          networkNameInput.setAttribute("value", name)

          if (url) {
            const regex = /https:\/\/.*\.meraki.com\/.*\/n\/(.*)\/manage\/.*/g
            const match = regex.exec(url)
            const hash = match[1]

            // Change network hash
            networkHashInput.setAttribute("value", hash)
            $(networkHashHint).text(hash)
          }
        })
      }
    })
  }
}
