import { Calendar } from "@fullcalendar/core"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from '@fullcalendar/interaction';

$(function($) {
  document.addEventListener("turbo:load", function(){
    const calendarEl = document.querySelector("#alltask-calendar");
    if (calendarEl == undefined) {
      return
    }

    const eventSource = calendarEl.dataset.eventSource

    var calendar = new Calendar(calendarEl, {
      plugins: [ dayGridPlugin, interactionPlugin ],
      headerToolbar: {
        left: "today prev,next",
        center: "title",
        right: "dayGridWeek dayGridMonth"
      },
      initialView: "dayGridMonth",
      weekNumbers: true,
      businessHours: true,
      nowIndicator: true,
      firstDay: 1,
      editable: true,
      contentHeight: "auto",
      events: eventSource,
      eventDataTransform: function(eventData) {
        const new_text = eventData.title.split("\n")

        eventData.title = new_text[0]
        eventData.description = new_text[1]
        eventData.assignee_name = new_text[2] == undefined ? "Unassigned" : new_text[2]
      },
      eventContent: function(arg) {
        return {
          html: `
            <b>${arg.event._def.title}</b><br/>
            <span>${arg.event._def.extendedProps.description}</span><br/>
            <span>${arg.event._def.extendedProps.assignee_name}</span>
          `,
        }
      },
      eventResize: function(info) {
        const updateUrl = info.event._def.extendedProps.update_url
        const startDate = info.event.start
        const endDate = info.event.end
        
        updateTaskDates(updateUrl, startDate, endDate, this)
      },
      eventDrop: function(info) {
        const updateUrl = info.event._def.extendedProps.update_url
        const newStartDate = info.event.start
        const newEndDate = info.event.end

        if (info.oldEvent.start != newStartDate || info.oldEvent.end != newEndDate) {
          // If we're going to update the assignee later, no need to reload events now
          updateTaskDates(updateUrl, newStartDate, newEndDate, this)
        }
      },
      eventClick: function(info) {
        info.jsEvent.preventDefault()

        updateModal(info.event)
        $("#alltask-modal").modal("show")
      },
    })

    calendar.render()
  })

  /**
   * Updates the task dates
   * @param {String} updateURL The API URL to update the task
   * @param {Date}   startDate The start date of the task
   * @param {Date}   endDate   The end date of the task
   * @param {Object} calendar  The calendar element
   */
   function updateTaskDates(updateUrl, startDate, endDate, calendar) {
    // The end date sent is exclusive meaning we need to reduce it by 1 day before sending it.
    endDate.setDate(endDate.getDate() - 1)

    $.ajax({
      url: updateUrl,
      method: "patch",
      data: { event: { from: startDate, to: endDate } }
    }).success(function() {
      if (calendar) {
        calendar.refetchEvents()
      }
    }).fail(function(data) {
      console.error(`${data.status} - ${data.statusText}`)
    })
  }

  /**
   * Capitalizes the first letter of a string
   * @param  {String} s The string to capitalize
   * @return {String}   The capitalized string
   */
   function capitalize(s) {
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  /**
   * Transform a string into human format
   * @param  {String} s The string to humanize
   * @return {String}   The humanized string
   */
  function humanize(s) {
    const words = s.match(/[A-Za-z][a-z]*/g) || [];
    return words.map(capitalize).join(" ");
  }

  /**
   * Updates the information in the modal.
   * @param {Object} event The event object coming from FullCalendar
   */
     function updateModal(event) {
       // If there's no project ID, hide the project button.
      if (event._def.extendedProps.project_id) {
        $("#project").attr("href", `/vista/projects/${event._def.extendedProps.project_id}`)
        $("#project").show()
      } else {
        $("#project").hide()
      }

      console.log(event._def)

      $("#edit").attr("href", `/admin/tasks/${event._def.publicId}`)
      $("#main-title").text(`${capitalize(event._def.extendedProps.situation)} ${capitalize(event._def.extendedProps.kind)} ${event._def.title.split(" ")[0]}`)
      $("#server .value").text(event._def.extendedProps.server_name).attr("href", `/vista/servers/${event._def.extendedProps.server_id}`)
      $("#description .value").text(event._def.extendedProps.description.trim())
      $("#status .value").text(humanize(event._def.extendedProps.status))
      $("#status .value").css("background", event._def.ui.backgroundColor)
      $("#from .value").text(event._def.extendedProps.from)
      $("#to .value").text(event._def.extendedProps.to)
      $("#assignee .value").text(event._def.extendedProps.assignee_name)
      $("#notify .value").text(event._def.extendedProps.notify ? "On" : "Off")
    }
})
