import { Controller } from "@hotwired/stimulus"
import { Chart } from "chart.js/auto"

const DOWNLOAD_KEY = "Download"
const UPLOAD_KEY = "Upload"
const SPEEDTEST_KEY = "Speedtest ↓"

export default class extends Controller {
  static targets = [ "chart", "option" ]

  initialize() {
    const chartEl = this.chartTarget
    const range = chartEl.dataset.range
    const url = chartEl.dataset.url

    const chart = new Chart(chartEl, {
      type: "line",
      data: {},
      options: {
        animation: false
      }
    })

    // Load data from remote source
    $.ajax({
      url: url + `?range=${range}`,
      type: "GET",
      success: (data) => {
        this.updateData(data, chart)           // Update chart data.
        this.setOptionsAndRedraw(chart, range) // Redraw chart with new data and options.
        this.updateTable(data)                 // Show values in the table below the chart.
      }
    })
  }

  loadRange(e) {
    // If the same option was selected or disabled, don't do anything
    if (e.target.classList.contains("selected") || e.target.classList.contains("disabled")) {
      return
    }

    const chartEl = this.chartTarget
    const chart = Chart.getChart(chartEl)
    const url = chartEl.dataset.url
    const range = e.target.dataset.range
    const options = this.optionTargets

    // Remove selected from selected option(s) and add disabled until loading is finished.
    options.forEach((option) => {
      option.classList.remove("selected")
      option.classList.add("disabled")
    })

    // Add selected class to the one that was clicked and remove disabled.
    e.target.classList.add("selected")
    e.target.classList.remove("disabled")

    // Get data from API and reload graph with new data
    $.ajax({
      url: url + `?range=${range}`,
      type: "GET",
      success: (data) => {
        this.updateData(data, chart, range)           // Update chart data.
        this.setOptionsAndRedraw(chart, range) // Redraw chart with new data and options.
        this.updateTable(data)                 // Show values in the table below the chart.

        // Re-enable buttons to change range.
        options.forEach((option) => {
          option.classList.remove("disabled")
        })
      }
    })
  }

  /* HELPERS */

  /**
  * Sets new options for a chart and updates it.
  * @param {Object} chart The chart.js object.
  * @param {String} range The range of time we want to display. This will have an impact on labels and tooltips.
  */
  setOptionsAndRedraw(chart, range) {
    chart.options = {
      maintainAspectRatio: false,
      animation: false,
      plugins: {
        legend: false,
        tooltip: {
          itemSort: this.sortLabels,
          callbacks: {
            title: (context) => {
              return this.titleCallback(context, range)
            }
          }
        }
      },
      scales: {
        y: {
          grid: {},
          min: 0,
        },
        x: {
          ticks: {
            minRotation: 0,
            maxRotation: 0,
            maxTicksLimit: 7,
            callback: function(value, index, ticks) {
              const label = this.getLabelForValue(value)

              switch(range) {
                case "daily":
                  const date = moment(label, "HH:mm")
                  if (date.minutes() == 0 && date.seconds() == 0) {
                    return date.format("h A")
                  }
                  break
                case "weekly":
                case "monthly":
                  return moment(label, "MMM D, H a").format("MMM D")
                default:
                  return label
              }
            }
          }
        }
      },
      interaction: {
        intersect: false,
        mode: "index"
      },
      elements: {
        point: {
          radius: 0
        }
      }
    }

    // Redraw to apply config changes
    chart.update()
  }

  /**
  * Updates the chart with new data.
  * @param {Array}  arr   The array of elements.
  * @param {Object} chart The chart.js object.
  * @param {String} range The range of time we want to display.
  */
  updateData(data, chart, range) {
    // If the data is empty, stop here..
    if (data && Object.keys(data).length === 0) {
      return
    }

    // Grab the download data
    const speedtestTable = document.querySelector("#chart-table")
    let d = data.find((element) => element.name == DOWNLOAD_KEY)

    // If we don't have download data, grab the speedtest data instead.
    if (Object.keys(d.data).length == 0) {
      d = data.find((element) => element.name == SPEEDTEST_KEY && (["weekly", "monthly", "6_months"].includes(range)))
    }

    let newData = {
      labels: Object.keys(d.data),
      datasets: []
    }

    // If we can't find a `Download` or `Speedtest` element, reset data in the chart and hide the numbers table.
    if (d == undefined || d.data == undefined || Object.keys(d.data).length === 0) {
      speedtestTable.style.display = "none"
      chart.config._config.data = newData
      chart.update()
      return
    } else {
      speedtestTable.style.display = ""
    }

    data.forEach((dataset) => {
      let d = {}

      // Assign the name of the dataset to the label
      d.label = dataset.name

      // Get all the values added into the data. We don't care about the keys, because we already set them as the labels earlier.
      d.data = Object.values(dataset.data)

      // Modify styling based on the label
      const styles = this.styleForLabel(dataset.name)
      d.borderColor = d.pointBackgroundColor = d.pointHoverBackgroundColor = styles.border
      d.backgroundColor = styles.background
      d.fill = styles.fill

      // Constant styles
      d.borderWidth = 2
      d.pointHitRadius = 50
      d.tension = 0.4

      newData.datasets.push(d)
    })

    chart.config._config.data = newData
    chart.update()
  }

  /**
  * Updates the table containing the max and avg values.
  * @param  {Object} data The data for download, upload and speedtest.
  */
  updateTable(data) {
    const rowDict = {
      [DOWNLOAD_KEY]: "download",
      [UPLOAD_KEY]: "upload",
      [SPEEDTEST_KEY]: "speedtest"
    }

    // Find the maximum and average for each element in data.
    data.forEach((element) => {
      const row = document.querySelector(`#${rowDict[element.name]}-row`)
      let values = Object.values(element.data)
      let max = values.reduce((a, b) => Math.max(a, b), -Infinity)
      let avg = this.average(values)

      // If the max value if -Infinity, we want to hide the row.
      if (max == -Infinity) {
        row.style.display = "none"
        return
      }

      // Update the text in the rows
      row.style.display = ""
      const rowValues = row.querySelectorAll("td:not(.title)")

      rowValues[0].textContent = `${max} Mbps`
      rowValues[1].textContent = `${avg} Mbps`
    })
  }

  /**
  * Sorts the label.
  * @param  {Integer} a The first element to sort.
  * @param  {Integer} b The second element to sort.
  * @return {Integer}   A number to sort by.
  */
  sortLabels(a, b) {
    const labelMap = {
      [SPEEDTEST_KEY]: 0,
      [DOWNLOAD_KEY]: 1,
      [UPLOAD_KEY]: 2
    }

    return labelMap[a.dataset.label] - labelMap[b.dataset.label]
  }

  /**
  * Find the correct styles for a given label.
  * @param  {String}  label The string to get colors for.
  * @return {Object}        An object that has all the colors needed for the given label.
  */
  styleForLabel(label) {
    const labelColors = {
      [SPEEDTEST_KEY]: {
        background: "red",
        border: "red",
        fill: false
      },
      [DOWNLOAD_KEY]: {
        background: "rgba(76, 187, 23, 0.5)",
        border: "#4CBB17",
        fill: true
      },
      [UPLOAD_KEY]: {
        background: "rgba(15, 82, 186, 0.5)",
        border: "#0F52BA",
        fill: true
      }
    }

    return labelColors[label]
  }

  /**
  * Displays a custom format for the title.
  * @param  {Object} context The context of the title callback.
  * @param  {range}  range   The range of time we want to display. This will have an impact on labels and tooltips.
  * @return {String}         A formatted label.
  */
  titleCallback(context, range) {
    let label = context[0].label || ""

    if (range == "weekly" || range == "monthly") {
      return moment(label, "MMM D, hA").format("YYYY-MM-DD hA")
    }

    return context[0].label
  }

  /**
  * Calculate the average.
  * @param  {Object} arr The array of elements we want to find the average of.
  * @return {Float}      The average of elements in the array.
  */
  average(arr) {
    if (arr.length == 0) {
      return -Infinity
    }

    let sum = 0;

    for (let i = 0; i < arr.length; i++){
        sum += parseFloat(arr[i], 10)
    }

    return Math.round((sum / arr.length) * 100 + Number.EPSILON) / 100
  }
}
