import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "description" ]

  copyToDescription() {
    const mde = App.NewMarkdownEditor.get_instances()[0]
    const url = $("#email_preview")[0].src + "_text"

    $.ajax({
      url: url,
      type: "GET",
      success: function(data) {
        console.log(data)
        mde.value(data)
      }
    })
  }
}
