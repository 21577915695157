import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "partial" ]

  initialize() {
    this.savedStrategy = this.partialTarget.dataset.value
  }

  change(event) {
    if (event.target.value != this.savedStrategy) {
      this.partialTarget.style.display = "none"
    } else {
      this.partialTarget.style.display = "block"
    }
  }
}
