import consumer from "./consumer"
import toastr from "toastr";

toastr.options = {
  positionClass: "toast-top-right",
  timeOut: 60000,
  extendedTimeOut: 120000,
  newestOnTop: true,
  progressBar: true,
  showMethod: 'slideDown',
  closeButton: false
}

consumer.subscriptions.create("PhoneCallNotificationsChannel", {
  received(data) {
    // Update the onclick event to redirect to the call.
    toastr.options.onclick = function() {
      window.location.href = `/admin/calls/${data.object.id}/edit`
    }

    toastr["info"](`You have a new call!</br><strong>#CA${data.object.id}</strong> from ${data.object.caller_id}`)
  }
})
