import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "element" ]

  connect() {
    const element = this.elementTarget

    if (element.hasAttribute("data-reload")) {
      // Reload init insert and markdown editor
      App.init_on_insert()
      new App.NewMarkdownEditor()
    }
  }
}
