import consumer from "./consumer"
import toastr from "toastr";

consumer.subscriptions.create("NatsNotificationsChannel", {
  received(data) {
    if (data.status === "200"){
      toastr["info"]( data.message );
    } else if (data.status === "302") {
      toastr["success"]( data.message );
    } else {
      toastr["error"]( data.message );
    }
  }
})
