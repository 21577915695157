import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    const interval = this.element.dataset.interval

    // Reload the Turbo Frame every 10 seconds
    this.reloadInterval = setInterval(this.reloadFrame.bind(this), interval);
  }

  disconnect() {
    // Clear the reload interval when the controller is disconnected
    clearInterval(this.reloadInterval);
  }

  reloadFrame() {
    const frame = document.getElementById(this.element.id);
    frame.src = frame.src; // Reload the frame
  }
}
