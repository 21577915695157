import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  inputTargetConnected(element) {
    switch (element.dataset.type) {
      case "currency":
        this.applyMask(element, this.currencyMask)
        break
      case "mac":
        this.applyMask(element, this.macAddressMask)
        break
      case "integer":
        this.applyMask(element, this.integerMask)
        break
      default:
        if ("type" in element.dataset) {
          console.warn(`Unknown mask type: ${element.dataset.type}`)
        } else {
          console.warn("Please provide a `data-type` for the type of mask to your target.")
        }
    }
  }

  inputTargetDisconnected(element) {
    element.removeEventListener("input", element._inputListener)
    element.removeEventListener("paste", element._pasteListener)
    delete element._inputListener
    delete element._pasteListener
  }

  /**
  * MAC Address mask for text input
  * Ex: 11:22:33:44:55:66
  * @param {Object} element The input to mask.
  */
  macAddressMask = function(element) {
    let mac = element.value.replace(/[^a-fA-F0-9]/g, "").substring(0, 12)
    element.value = mac.match(/.{1,2}/g)?.join(":") || ""
  }

  /**
  * Currency mask for text input
  * Additionally, if the input has a round amount and show 2 decimal places.
  * Ex: 35.0 -> 35
  * @param {Object} element The input to mask.
  */
  currencyMask(element) {
    const regex = /^[0-9]*[.,]?[0-9]{0,2}$/;

    // Handle input validation without formatting
    if (!regex.test(element.value)) {
      element.value = element.value.slice(0, -1);
    }

    // Ensure replacing commas with periods
    if (element.value.includes(",")) {
      element.value = element.value.replace(",", ".");
    }
  }

  /**
  * Currency mask for integer input
  * @param {Object} element The input to mask.
  */
  integerMask(element) {
    const regex = /^\d*$/
    if (!regex.test(element.value)) {
      element.value = element.value.slice(0, -1)
    }
  }

  /* FUNCTIONS */

  applyMask(element, maskFunction) {
    element._inputListener = maskFunction.bind(this, element) // Store event handler for cleanup
    element.addEventListener("input", element._inputListener)

    if (maskFunction === this.currencyMask || maskFunction === this.integerMask) {
      element._pasteListener = this.pasteHandler.bind(this, element, maskFunction)
      element.addEventListener("paste", element._pasteListener)
    }
  }

  pasteHandler(_, maskFunction, event) {
    const pasteText = event.clipboardData.getData("text")
    if (maskFunction === this.currencyMask && !/^\d+([.,]\d{0,2})?$/.test(pasteText)) {
      event.preventDefault()
    } else if (maskFunction === this.integerMask && isNaN(parseInt(pasteText, 10))) {
      event.preventDefault()
    }
  }
}
