import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  productTooltip(event) {
    const productList = $(event.target).data("product-list")

    $(event.target).popover({
      title: "Product list",
      content: productList,
      trigger: "focus", // Doesn't seem to work as intended, but also fixes a weird double-click issue ¯\_(ツ)_/¯
      html: true,
      container: "body"
    })
    $(event.target).popover("toggle")
  }

  devices(event) {
    const contractId = $(event.currentTarget).data("contractId")
    const productId = $(event.currentTarget).data("productId")
    const title = $(event.currentTarget).data("title")
    const destination = $("#modal-products-devices")[0]

    $.ajax({
      dataType: "html",
      type: "get",
      url: "/admin/contracts/" + contractId + "/product_devices?server_id=" + productId,
      success: function(data) {
        destination.innerHTML = data

        $($(destination).find(".devices-duallistbox")).DualListBox({
          json: false,
          title: title
        })

        $($(destination).find(".selected")).attr("name", "contract[devices][]") // For some reason, the name if removed from the input when swapping data
      }
    });
  }

  serviceOptions(event) {
    $(".hidden-server-field")[0].value = $(event.currentTarget).data("productId")
    $(".added-at-field")[0].value = $(event.currentTarget).data("addedAt") || ""
    $(".support-priority-field").val($(event.currentTarget).data("supportPriority") || "").change()
    $(".service-options-field").val($(event.currentTarget).data("serviceOptions").map(x => x.id) || "").change()

    this.load_device_count(event.currentTarget)
    this.load_room_count(event.currentTarget)
  }

  terminate(event) {
    const productId = $(event.currentTarget).data("productId")
    const terminatedAt = $(event.currentTarget).data("terminatedAt") || ""
    const terminationReason = $(event.currentTarget).data("terminationReason") || ""
    const terminatedNote = $(event.currentTarget).data("terminatedNote") || ""

    $("#modal-products-termination .termination-hidden-server-id")[0].value = productId
    $("#modal-products-termination .terminated-at-field")[0].value = terminatedAt
    $("#modal-products-termination .termination-reason-field")[0].value = terminationReason
    $("#modal-products-termination .termination-reason-field").change() // Value was set, now trigger the change
    $("#modal-products-termination .terminated-at-note-field")[0].value = terminatedNote
  }

  loadMore() {
    const list = $(".products-section .list-group tbody")
    const elements = $(list).find("tr.hidden")
    const showButton = $(list).find(".show-products")

    elements.removeClass("hidden")
    showButton.addClass("hidden")
  }

  hide() {
    const list = $(".products-section .list-group tbody")
    const elements = $(list).find("tr.hidden-products")
    const hideButton = $(list).find(".hide-products")
    const showButton = $(list).find(".show-products")

    elements.addClass("hidden")
    hideButton.addClass("hidden")
    showButton.removeClass("hidden")

    $(".products-section").get(0).scrollIntoView({ behavior: "smooth", block: "center" }) // Scroll to top of list
  }

  /* === Helper functions === */

  load_device_count(target) {
    $(".devices-count-row").not(":first").remove() // To make sure we don't duplicate rows when we open the modal again

    if ($(target).data("devicesCount") == null)
      return

    const devices_count = $(target).data("devicesCount")

    // Set values
    let i = 1
    for (let type in devices_count) {
      const new_row = $(".devices-count-row").first().clone()
      const select = new_row.find(".autosearch_type")[0]
      const count = new_row.find("input[id='_contract_devices_count_0count']")[0]

      select.id = select.id.replace("_contract_devices_count_0type", "_contract_devices_count_" + i + "type")
      select.name = select.name.replace("[contract][devices_count][0]type", "[contract][devices_count][" + i + "]type")
      count.id = count.id.replace("_contract_devices_count_0count", "_contract_devices_count_" + i + "count")
      count.name = count.name.replace("[contract][devices_count][0]count", "[contract][devices_count][" + i + "]count")
      count.value = devices_count[type] // Change the number of rooms value

      // Re-apply select2
      $(select).select2({
        theme: "bootstrap",
        width: "100%"
      })
      $(select).val(type).trigger("change")

      $("#modal-products-service-options .devices-count").append(new_row.show("")) // Append new row to list
      i++
    }
  }

  load_room_count(target) {
    if ($(target).data("roomCount") == null) {
      $("input[id='number_of_rooms']")[0].value = ""
    } else {
      $("input[id='number_of_rooms']")[0].value = $(target).data("roomCount")
    }
  }
}
