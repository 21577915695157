import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "list" ]

  loadMore() {
    const list = this.listTarget
    const elements = $(list).find("tr.hidden")
    const showButton = $(list).find(".show-invoices")

    elements.removeClass("hidden")
    showButton.addClass("hidden")
  }

  hide() {
    const list = this.listTarget
    const elements = $(list).find("tr.hidden-invoices")
    const hideButton = $(list).find(".hide-invoices")
    const showButton = $(list).find(".show-invoices")

    elements.addClass("hidden")
    hideButton.addClass("hidden")
    showButton.removeClass("hidden")

    $(".invoices-section").get(0).scrollIntoView({ behavior: "smooth", block: "center" }) // Scroll to top of list
  }
}
