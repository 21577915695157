import { Controller } from "@hotwired/stimulus"

const MAX_SCORE_PER_CRITERIA = 5
const STATUS_IN_REVIEW = "in_review"

export default class extends Controller {
  static targets = [ "criteria", "status" ]

  submit(event) {
    if (this.statusTarget.value == STATUS_IN_REVIEW) {
      return
    }

    event.preventDefault()

    const lang = event.target.dataset.lang
    const elements = this.criteriaTargets
    let maxScore = 0
    let curScore = 0

    elements.forEach((el) => {
      var input = el.querySelector("input:not([type='hidden'])")
      var weight = parseInt(el.dataset.weight)
      var value = this.inputValue(input)

      maxScore += weight
      curScore += this.calculateScore(value, weight)
    })

    const percentage = this.calculatePercentage(curScore, maxScore)
    const confirmed = confirm(this.confirm_text(lang, percentage))

    if (confirmed) {
      const submitBtn = event.target.querySelector("input[type='submit']")
      submitBtn.disabled = true // Prevent users from doubling clicking
      event.target.submit()
    }
  }

  /**
   * Finds the value of a given input.
   * @param {Object} input The input which we want to read the value from.
   * @return {Float}
  */
  inputValue(input) {
    switch (input.type) {
      case "checkbox":
        return input.checked ? 5.0 : 0.0
      case "range":
        return parseFloat(input.value)
    }
  }

  /**
   * Calculates the weighted score based on the criteria's value and weight.
   * @param {Float} value  The score of the criteria.
   * @param {Float} weight The weight of the criteria.
   * @return {Float}
  */
  calculateScore(value, weight) {
    return parseFloat(value) / MAX_SCORE_PER_CRITERIA * weight
  }

  /**
   * Calculates the percentage rounded to the integer.
   * @param {Float} curScore The current score.
   * @param {Float} maxScore The maximum score.
   * @return {Float}
  */
  calculatePercentage(curScore, maxScore) {
    return Math.round(curScore / maxScore * 100)
  }

  /**
   * Text for the confirm message based on the user's language preference.
   * @param {String} lang       The user's language preference.
   * @param {Float}  percentage The maximum score.
   * @return {String}
  */
  confirm_text(lang, percentage) {
    switch (lang) {
      case "en":
        return `The agent score will be ${percentage}%. If the call is set to 'reviewed', your notes will be shared with the agent.\n\nAre you sure?`
      case "fr":
        return `Le score de l'agent sera de ${percentage}%. Si le statut de la revue est changé pour "révisé", vos notes seront partagées avec l'agent.\n\nÊtes-vous sûr ?`
    }
  }
}
