import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "organizationId", "organizationName", "venueId", "venueName" ]

  loadOrganizations() {
    // Add a spinner while we're fetching organizations from Shasta
    $("#shasta-org-spinner").show()

    const self = this
    const serverId = this.element.dataset.serverId
    const organizationInput = $(this.organizationIdTarget)
    const organizationValue = organizationInput[0].value
    const venueIdInput = this.venueIdTarget
    const venueNameInput = this.venueNameTarget

    $.ajax({
      url: `/vista/servers/${serverId}/wireless_infrastructure/load_shasta_organizations`,
      dataType: "json",
      type: "GET",
      success: function(data) {
        const organizations = data
        const firstOption = organizationInput[0][0]
        organizationInput.empty()

        // Insert blank option, then add all organizations. Select the option with the current value.
        organizationInput.append(firstOption)
        $.each(organizations, function(_, value) {
          organizationInput.append($("<option></option>").attr("value", value[1]).text(value[0]))
        })

        // Transform into a select2 field, automatically open it.
        organizationInput.select2({
          theme: "bootstrap",
          width: "100%"
        })

        organizationInput.val(organizationValue).trigger("change")
        organizationInput.off("select2:select")
        organizationInput.select2("open")

        if (organizationInput.size > 0) {
          self.organizationNameTarget.setAttribute("value", organizationInput[0].options[organizationInput[0].selectedIndex].text)
        }

        // Hide spinner
        $("#shasta-org-spinner").hide()

        // Start loading venues if an org is selected.
        if (organizationValue) {
          self.loadVenues()
        }

        // Listen for a change of org in order to load venues
        organizationInput.on("select2:select", function(e) {
          // Change the hidden input value
          const selected = e.target.value

          // Clear the network name and hash
          venueNameInput.removeAttribute("value")

          if (selected) {
            self.organizationNameTarget.setAttribute("value", organizations.find(n => n[1] == selected)[0])
            self.loadVenues()
          } else {
            self.organizationNameTarget.removeAttribute("value")
            // Clear all values from venues except the blank value
            $(venueIdInput).find("option").not(":first").remove()
          }
        })
      }
    })
  }

  loadVenues() {
    const serverId = this.element.dataset.serverId
    const organizationValue = this.organizationIdTarget.value
    const venueIdInput = $(this.venueIdTarget)
    const venueNameInput = this.venueNameTarget

    const initialVenueValue = venueIdInput[0].value

    // Add a spinner while we're fetching venues from Shasta
    $("#shasta-network-spinner").show()

    // Get list of venues and handle the input
    $.ajax({
      url: `/vista/servers/${serverId}/wireless_infrastructure/load_shasta_venues`,
      dataType: "json",
      data: { organization: organizationValue },
      type: "GET",
      success: function(data) {
        const venues = data
        const firstOption = venueIdInput[0][0]
        venueIdInput.empty()

        // Insert blank option, then add all venues. Select the option with the current value.
        venueIdInput.append(firstOption)
        $.each(venues, function(_, value) {
          venueIdInput.append($("<option></option>").attr("id", value[1]).attr("value", value[1]).text(value[0]))
        })

        // Transform into a select2 field
        venueIdInput.select2({
          theme: "bootstrap",
          width: "100%"
        })

        // Preselect values if the preselected venues exists in the list
        if (venues && venues.length > 0 && venues.filter(venue => venue[1] == initialVenueValue).length > 0) {
          venueIdInput.val(initialVenueValue).trigger("change")
        } else {
          // Otherwise select the blank input
          venueIdInput.val("").trigger("change")
        }

        const selectedVenue = venueIdInput[0].options.namedItem(initialVenueValue)
        if (selectedVenue) {
          venueNameInput.setAttribute("value", selectedVenue.text)
        }

        venueIdInput.off("select2:select")

        // Hide spinner
        $("#shasta-network-spinner").hide()

        // On select2:select
        venueIdInput.on("select2:select", function(e) {
          const selected = venues.find(n => n[1] == e.target.value)

          if (!selected) {
            venueNameInput.removeAttribute("value")
            return
          }

          const name = selected[0]

          // Change hidden input name
          venueNameInput.setAttribute("value", name)
        })
      }
    })
  }
}
