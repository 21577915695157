import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "siteName" ]

  loadSites(event) {
    // Add a spinner while we're fetching sites from Ignitenet
    $("#ignitenet-site-spinner").show()

    const serverId = this.element.dataset.serverId
    const siteInput = $(event.currentTarget)
    const siteNameInput = this.siteNameTarget
    const siteValue = siteInput[0].value

    $.ajax({
      url: `/vista/servers/${serverId}/wireless_infrastructure/load_ignitenet_sites`,
      dataType: "json",
      type: "GET",
      success: function(data) {
        const sites = data
        const firstOption = siteInput[0][0]
        siteInput.empty()

        // Insert blank option, then add all sites. Select the option with the current value.
        siteInput.append(firstOption)
        $.each(sites, function(_, value) {
          siteInput.append($("<option></option>").attr("value", value[1]).text(value[0]))
        })

        // Transform into a select2 field, automatically open it.
        siteInput.select2({
          theme: "bootstrap",
          width: "100%"
        })
        siteInput.val(siteValue).trigger("change")
        siteInput.off("select2:select")
        siteInput.select2("open")

        // Hide spinner
        $("#ignitenet-site-spinner").hide()

        // On select, update the hidden name field
        siteInput.on("select2:select", function(e) {
          const selected = sites.find(n => n[1] === e.target.value)

          if (!selected) {
            siteNameInput.removeAttribute("value")
            return
          }

          const name = selected[0]

          // Change hidden input name
          siteNameInput.setAttribute("value", name)
        })

      }
    })
  }
}
