import { Controller } from "@hotwired/stimulus"
let debounce = require('lodash/debounce');

export default class extends Controller {
  static targets = [ "sidebar", "count" ]

  initialize() {
    this.roomNumber = debounce(this.roomNumber, 500).bind(this) // wait .5 seconds after last input before firing event
  }

  roomNumber(event) {
    let roomNumber = event.target.value
    const callId = event.target.dataset.callId
    const sidebarRelatedCalls = this.sidebarTarget
    const relatedCallsCount = this.countTarget

    if (roomNumber == "")
      roomNumber = undefined

    $.ajax({
      dataType: "html",
      type: "get",
      url: "/admin/calls/" + callId + "/reload_related_calls?room_number=" + roomNumber,
      success: function(data) {
        sidebarRelatedCalls.innerHTML = data

        // Add proper number of related calls in the header
        let str = relatedCallsCount.textContent.split(" ")
        str[0] = String($(sidebarRelatedCalls).find(".call").length)
        relatedCallsCount.textContent = str.join(" ")

        $(sidebarRelatedCalls).find("[data-toggle='tooltip']").tooltip() // Re-apply tooltips
      }
    });
  }
}
