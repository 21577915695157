import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "mode", "full", "daily" ]

  initialize() {
    this.hideFields(this.modeTarget.value)
  }

  change() {
    this.hideFields(this.modeTarget.value)
  }

  /* FUNCTIONS */

  /**
  * Hide fields based on a given mode
  * @param {String} mode The mode of the maintenance schedule.
  */
  hideFields(mode) {
    const full = this.fullTargets
    const daily = this.dailyTargets

    switch(mode) {
      case "permanent":
        this.setClass(full.concat(daily), "add")
        break
      case "full":
        this.setClass(full, "remove")
        this.setClass(daily, "add")
        break
      case "daily":
        this.setClass(full, "add")
        this.setClass(daily, "remove")
        break
    }
  }

  /**
  * Adds or removes a class from an array of elements.
  * @param  {Array}  arr       The array of elements.
  * @param  {String} operation The operation we want to perform.
  * @return {Boolean}          True if the operation was completed.
  */
  setClass(arr, operation) {
    if (typeof arr[Symbol.iterator] !== "function") {
      return false
    }

    arr.forEach((e) => {
      if (operation == "remove") {
        e.classList.remove("hidden")
      } else {
        e.classList.add("hidden")
      }
    })

    return true
  }
}
