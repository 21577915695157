import consumer from "./consumer"

if ($("#meta-contract").length > 0) {
  var class_id = $("#meta-contract").attr("class_id")
  consumer.subscriptions.create(
    {
      channel: "ContractCommentNotificationsChannel",
      contract_id: class_id
    },{
      received: function(data) {
        $.ajax({
          url: `/admin/contracts/${class_id}/comments/${data}/render_comment`,
          type: "get",
          success: function(data) { data }
        })
      }
    }
  );
}
