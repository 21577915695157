import { Chart } from "chart.js/auto"

$(function($) {
  document.addEventListener("turbo:load", function() {
    const charts = document.querySelectorAll(".donut_chart")

    charts.forEach((chart) => {
      let data = chart.dataset.content
      data = JSON.parse(data)

      const labels = data.map((x) => x.label)
      const values = data.map((x) => x.value)
      const colors = colorsForChartKind(chart.dataset.chart, data)

      let hovered = labels[0] || ""
      let hoveredCount = values[0] || ""

      new Chart(chart, {
          type: "doughnut",
          data: {
              labels: labels,
              datasets: [{
                  label: "",
                  data: values,
                  backgroundColor: colors,
                  borderWidth: 1,
                  cutout: "80%",
                  hoverOffset: 20,
                  rotation: 180
              }]
          },
          options: {
            layout: {
              padding: 15,
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: false,
                    external: function(context) {
                        // By passing nothing, we remove the hover animation,
                        // but keep the event in order to display text in the middle
                        return
                    }
                },
            },
            onHover: (e) => {
                if (e.chart.tooltip.dataPoints) {
                    hovered = e.chart.tooltip.dataPoints[0].label
                    hoveredCount = e.chart.tooltip.dataPoints[0].formattedValue
                }
            }
          },
          plugins: [{
              id: "stackedText",
              afterDatasetDraw: (chart, args, options) => {
                const { ctx, chartArea: { top, bottom, left, right, width, height} } = chart
                ctx.save()

                if (hovered.length < 10) {
                  ctx.font = `bolder ${width / 6}px Arial`
                } else {
                  ctx.font = `bolder ${width / (hovered.length / 2)}px Arial`
                }

                ctx.textAlign = "center"
                ctx.fillText(hovered, width / 2 + left, height / 2 + top)

                ctx.font = `${width / 8}px Arial`
                ctx.fillText(hoveredCount, width / 2 + left, height / 2 + top + (height / 7))
              }
          }]
      });
    })

    function colorsForChartKind(chartKind, data) {
      switch(chartKind) {
        case "version-summary":
          return versionSummaryDonutColors(data)
        case "announcement", "server-status":
          return serverStatusDonutColors(data)
        case "default":
          return undefined
        case undefined:
          console.warn("No chart kind provided. Please provide a `data-chart` to your chart.")
          break
        default:
          console.warn("Unknown chart kind.")
      }
    }

    function versionSummaryDonutColors(data) {
      const colors = []

      data.forEach((x) => {
        if (x.latest === "true") {
          colors.push("rgba(112, 173, 37, 1)")
        } else {
          colors.push("rgb(246, 151, 48, 1)")
        }
      })

      return colors
    }

    function serverStatusDonutColors(data) {
      const colors = []

      data.forEach((x) => {
        if (x.status == "up") {
          colors.push("#70ad25")
        } else if (x.status == "down") {
          colors.push("#d53e2a")
        } else {
          colors.push("#f69730")
        }
      })

      return colors
    }
  })
})
