import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  initialize() {
    var form = this.element
    var elements = form.elements

    Array.from(elements).forEach(element => {
      element.disabled = true
    })
  }
}
