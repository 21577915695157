import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    this.initializeSelect2(this.element)
    this.bindSelectEvent(this.element)
  }

  disconnect() {
    $(this.element).select2("destroy")
  }

  /* FUNCTIONS */

  initializeSelect2(element) {
    let options = {
      theme: "bootstrap",
      width: "100%"
    }

    if (element.classList.contains("tag_list")) {
      Object.assign(options, {
        tags: true
      })
    }

    if (element.classList.contains("new_option")) {
      Object.assign(options, {
        tokenSeparators: [',', ' ']
      })
    }

    $(element).select2(options)
  }

  bindSelectEvent(element) {
    // Fire "change" event when "select2:select" is fired.
    $(element).on("select2:select", function() {
      let event = new Event("change", { bubbles: true })
      this.dispatchEvent(event)
    })
  }
}
