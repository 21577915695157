import consumer from "./consumer"

consumer.subscriptions.create("TaskNeedsUpdateNotificationsChannel", {
  received: function(data) {
    $(".badge-task").each(function(_, e) {
      const existingPriority = e.className.split(" ").filter(v => /task-p*/.test(v))[0]

      if (existingPriority) {
        $(e).removeClass(existingPriority)
      }

      if (data) {
        $(e).addClass(`task-p${data}`)
      }
    })
  }
})
