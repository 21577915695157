import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    this.initializeTooltip(this.element)
  }

  initializeTooltip(element) {
    const options = {
      container: "body",
      html: true,
      delay: {
        show: element.dataset.delayShow || 50,
        hide: element.dataset.delayHide || 50
      }
    }

    $(element).tooltip(options)
  }
}
