import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "suggestionBar", "siteSelect" ]

  useSuggested(event) {
    const selected_site_id = $(event.target).data("siteId")

    $(this.siteSelectTarget).val(selected_site_id).trigger("change").trigger("select2:select")
    $(this.suggestionBarTarget).hide()
  }
}
