import consumer from "./consumer"

consumer.subscriptions.create("GeneralNotificationsChannel", {
  received(data) {
    var original_html = $(".badge-ticket").html()
    $(".badge-ticket").html($("#triage_badge").html().replace($("#triage_badge").text().replace(/(\n)/gm, ""), data["triage_badge_text"]))
    if (original_html !== $(".badge-ticket").html()) {
      $(".badge-ticket").css({"background-color":  "#e6e600", "color": "black"});
      setTimeout(function(){
        $(".badge-ticket").css({"background-color": "#8a0c8f", "color": "white"});
      }, 2000)
    }
  }
})
