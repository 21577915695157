import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  import(event) {
    const input = this.inputTarget
    const fileInput = event.currentTarget

    // Find the file from the input
    const file = fileInput.files[0]

    if (file) {
      const reader = new FileReader()
      reader.readAsText(file, "UTF-8")

      reader.onload = (evt) => {
        input.value = evt.target.result
      }

      reader.onerror = () => {
        console.warn("Error reading file")
      }
    }
  }
}
