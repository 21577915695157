import consumer from "./consumer"

consumer.subscriptions.create("UserNotificationsChannel", {
  received(data) {
    if (data["triage_task_text"] != null) {
      var original_html = $(".badge-task").html()
      $(".badge-task").html($("#triage_task").html().replace($("#triage_task").text().replace(/(\n)/gm, ""), data["triage_task_text"]))
      highlight_task_badge(".badge-task", "#e6e600")
    } else if (data["triage_project_text"] != null) {
      var original_html = $(".badge-project").html()
      $(".badge-project").html($("#triage_project").html().replace($("#triage_project").text().replace(/(\n)/gm, ""), data["triage_project_text"]))
      if (original_html !== $(".badge-project").html()) {
        highlight(".badge-project", "#275d8c", "#e6e600")
      }
    }
  }
})

function highlight_task_badge(css_class, highlight_color) {
  $(css_class).css({"background-image": `linear-gradient(-90deg, ${highlight_color}, ${highlight_color})`, "color": "black"});
  setTimeout(function(){
    $(css_class).css({"background-image": "", "color": "white"});
  }, 2000)
}

function highlight(css_class, original_color, highlight_color) {
  $(css_class).css({"background-color": highlight_color, "color": "black"});
  setTimeout(function(){
    $(css_class).css({"background-color": original_color, "color": "white"});
  }, 2000)
}
