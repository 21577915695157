import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "list" ]

  addRow() {
    const list = this.listTarget

    const number_devices_count_rows = $(".devices-count-row").length
    const new_row = $(".devices-count-row").first().clone()
    const select = new_row.find(".autosearch_type")[0]
    const count = new_row.find("input[id='_contract_devices_count_0count']")[0]

    select.id = select.id.replace(/_contract_devices_count_0type/, "_contract_devices_count_" + number_devices_count_rows + "type")
    select.name = select.name.replace("[contract][devices_count][0]type", "[contract][devices_count][" + number_devices_count_rows + "]type")
    count.id = count.id.replace(/_contract_devices_count_0count/, "_contract_devices_count_" + number_devices_count_rows + "count")
    count.name = count.name.replace("[contract][devices_count][0]count", "[contract][devices_count][" + number_devices_count_rows + "]count")

    // Re-apply select2
    $(select).select2({
      theme: "bootstrap",
      width: "100%"
    })

    $(list).append(new_row.show("")) // Append new row to list
  }

  removeRow(event) {
    $(event.target).closest(".devices-count-row").remove()
  }
}
