import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs'

var criteriaRow

export default class extends Controller {
  static targets = [ "criterias" ]

  initialize() {
    criteriaRow = this.element.querySelector("#criterias .criteria-row")
    Sortable.create(this.criteriasTarget, {
      draggable: ".criteria-row",
      animation: 150,
      filter: ".ignore-drag",
      preventOnFilter: false
    })
  }

  add() {
    const newCriteria = criteriaRow.cloneNode(true)

    // Reset values before appending
    newCriteria.querySelector("input").value = ""
    newCriteria.querySelector("select").value = "checkbox"
    newCriteria.classList.remove("hidden")

    this.criteriasTarget.appendChild(newCriteria)
  }

  remove(e) {
    const row = e.target.closest(".criteria-row")
    row.remove()
  }
}
