import axios from 'axios';
import TaskList from 'deckar01-task_list';

$(function($) {
  document.addEventListener("turbo:load", function() {
    const container = document.querySelector('.js-task-list-container');
    const el = document.querySelector('.js-task-list-field');
    const markdownEl = document.querySelector('.js-task-list-container .EasyMDEContainer');

    if (container) {
      updateEventListenerForToggle(true)
      new TaskList(container);

      el.style.display = "none"
      markdownEl.style.display = "none"
    }
  })

  $(document).ajaxStop(function() {
    updateEventListenerForToggle(false);
  });

  document.addEventListener("reloadChecklistEditor", function() {
    updateEventListenerForToggle(true)
    const el = document.querySelector('.js-task-list-container .EasyMDEContainer');
    el.style.display = "none"
  })

  function updateEventListenerForToggle(isFirstLoading) {
    const success_message = document.querySelector('#success_message');
    if (success_message && success_message.classList.contains('hidden') && !isFirstLoading) {
      return null
    }
    const link = document.querySelector('.checklist-editor-toggle');
    const el = document.querySelector('.js-task-list-container .EasyMDEContainer');
    if (link) {
      link.addEventListener('click', function (event) {
        // Prevent default link behavior
        event.preventDefault();
        if (el.style.display == "") {
          el.style.display = "none";
        } else {
          el.style.display = "";
        }
      });
    }
  }

  document.addEventListener('tasklist:changed', function(event) {
    const container = document.querySelector('.js-task-list-container');
    const text = event.target.value;
    const dataUrl = container.getAttribute('data-update-url');

    axios({
      method: 'PATCH',
      url: dataUrl,
      data: { description: text },
      headers: {
        'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content
      }
    })
    .then(function (response) {
      const new_text = JSON.parse(response.config.data)["description"]
      const instance = App.NewMarkdownEditor.get_instances().filter(x => x.element.id == "task_description")[0]

      instance.value(new_text)
    })
    .catch(function (error) {
      console.log(error);
    })
  })
})
