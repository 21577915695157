import { Calendar } from "@fullcalendar/core"
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from '@fullcalendar/interaction';

$(function($) {
  document.addEventListener("turbo:load", function(){
    const calendarEl = document.querySelector("#onsite-calendar");
    if (calendarEl == undefined) {
      return
    }

    const resourceTitle = calendarEl.dataset.title
    const eventSource = calendarEl.dataset.eventSource
    const resourceSource = calendarEl.dataset.resourceSource

    var calendar = new Calendar(calendarEl, {
      plugins: [ resourceTimelinePlugin, interactionPlugin ],
      headerToolbar: {
        left: "today prev,next",
        center: "title",
        right: ""
      },
      initialView: "resourceTimeLineThreeMonths",
      initialDate: getPreviousMonday(moment().subtract(7,'d').toDate()), // Load from the previous week's monday.
      resourceAreaWidth: "200px",
      height: "auto",
      nowIndicator: true,
      views: {
        resourceTimeLineThreeMonths: {
          type: "resourceTimeline",
          duration: { days: 90 },
          slotLabelFormat: [
            { month: "long" },
            { weekday: "short", month: "numeric", day: "numeric", omitCommas: true }
          ]
        }
      },
      businessHours: true,
      editable: true,
      resourceGroupField: "region",
      resourceOrder: "region,title",
      resources: resourceSource,
      resourceAreaColumns: [
        {
          field: "title",
          headerContent: resourceTitle
        }
      ],
      events: eventSource,
      eventMaxStack: 2,
      slotMinWidth: 150,
      eventDataTransform: function(eventData) {
        const new_text = eventData.title.split("\n")

        eventData.resourceId = eventData.assignee_id
        eventData.title = new_text[0].replace(/^(#)/, "") // Remove leading # for project number
        eventData.description = new_text[1]
      },
      eventContent: function(arg) {
        const situation = arg.event._def.extendedProps.situation

        return {
          html: `
            <b>${arg.event._def.title}</b>
            <div><i class="fa fa-${iconForSituation(situation)}"></i>${arg.event._def.extendedProps.description}</div>
          `,
        }
      },
      eventResize: function(info) {
        const updateUrl = info.event._def.extendedProps.update_url
        const startDate = info.event.start
        const endDate = info.event.end

        updateTaskDates(updateUrl, startDate, endDate, this)
      },
      eventDrop: function(info) {
        const updateUrl = info.event._def.extendedProps.update_url
        const newAssigneeId = info.event._def.resourceIds[0] // We only support 1 resource per event for now.
        const updateAssignee = info.oldEvent._def.resourceIds[0] != newAssigneeId

        // It's also possible to drop on the same assignee just to change the dates.
        const newStartDate = info.event.start
        const newEndDate = info.event.end
        if (info.oldEvent.start != newStartDate || info.oldEvent.end != newEndDate) {
          // If we're going to update the assignee later, no need to reload events now
          updateTaskDates(updateUrl, newStartDate, newEndDate, updateAssignee ? undefined : this)
        }

        // If the event was dropped onto a new resource, update the task assignee.
        if (updateAssignee) {
          updateTaskAssignee(updateUrl, newAssigneeId, this)
        }
      },
      eventClick: function(info) {
        info.jsEvent.preventDefault()

        updateModal(info.event)
        $("#onsite-modal").modal("show")
      },
      resourceGroupLabelDidMount: function(info) {
        // Remove leading numbers that are used for sorting groups.
        const results = info.el.textContent.match(/[0-9] - (.*)/)

        if (results.length >= 1) {
          $(info.el).find(".fc-datagrid-cell-main").contents()[0].textContent = results[1]
        }
      },
      schedulerLicenseKey: "CC-Attribution-NonCommercial-NoDerivatives"
    });

    calendar.render()
    calendar.scrollToTime({ days: 7 }) // Scroll to current week (since the initial date is the previous week's monday).
  })

  /**
   * Updates the task dates
   * @param {String} updateURL The API URL to update the task
   * @param {Date}   startDate The start date of the task
   * @param {Date}   endDate   The end date of the task
   * @param {Object} calendar  The calendar element
   */
  function updateTaskDates(updateUrl, startDate, endDate, calendar) {
    // The end date sent is exclusive meaning we need to reduce it by 1 day before sending it.
    endDate.setDate(endDate.getDate() - 1)

    const userEmail = document.querySelector("body").dataset.currentUserEmail

    $.ajax({
      url: updateUrl,
      method: "patch",
      data: { event: { from: startDate, to: endDate }, create_comment: true, email: userEmail }
    }).success(function() {
      if (calendar) {
        calendar.refetchEvents()
      }
    }).fail(function(data) {
      console.error(`${data.status} - ${data.statusText}`)
    })
  }

  /**
   * Updates the task assignee
   * @param {String} updateURL     The API URL to update the task
   * @param {Number} newAssigneeId The new assignee ID for this task
   * @param {Object} calendar      The calendar element
   */
  function updateTaskAssignee(updateUrl, newAssigneeId, calendar) {
    const userEmail = document.querySelector("body").dataset.currentUserEmail

    $.ajax({
      url: updateUrl,
      method: "patch",
      data: { event: { assignee_id: newAssigneeId }, create_comment: true, email: userEmail }
    }).success(function() {
      if (calendar) {
        calendar.refetchEvents()
      }
    }).fail(function(data) {
      console.error(`${data.status} - ${data.statusText}`)
    })
  }

  /**
   * Updates the information in the modal.
   * @param {Object} event The event object coming from FullCalendar
   */
  function updateModal(event) {
    $("#project").attr("href", `/vista/projects/${event._def.extendedProps.project_id}`)
    $("#edit").attr("href", `/admin/tasks/${event._def.publicId}`)
    $("#main-title").text(`${capitalize(event._def.extendedProps.situation)} ${capitalize(event._def.extendedProps.kind)} #TA${event._def.publicId}`)
    $("#server .value").text(event._def.extendedProps.server_name).attr("href", `/vista/servers/${event._def.extendedProps.server_id}`)
    $("#description .value").text(event._def.extendedProps.description.trim())
    $("#status .value").text(humanize(event._def.extendedProps.status))
    $("#status .value").css("background", event._def.ui.backgroundColor)
    $("#from .value").text(event._def.extendedProps.from)
    $("#to .value").text(event._def.extendedProps.to)
    $("#assignee .value").text(event._def.extendedProps.assignee_name)
    $("#notify .value").text(event._def.extendedProps.notify ? "On" : "Off")
  }

  /**
   * Capitalizes the first letter of a string
   * @param  {String} s The string to capitalize
   * @return {String}   The capitalized string
   */
  function capitalize(s) {
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  /**
   * Transform a string into human format
   * @param  {String} s The string to humanize
   * @return {String}   The humanized string
   */
   function humanize(s) {
    const words = s.match(/[A-Za-z][a-z]*/g) || [];
    return words.map(capitalize).join(" ");
  }

  /**
   * Find the previous monday
   * @param  {Date} date The date from which we want to find the previous monday
   * @return {Date}      The date of the monday in the given date's week.
   */
   function getPreviousMonday(date = new Date()) {
    return moment(date).startOf("isoWeek")._d;
  }

  /**
   * Icon name for a given situation.
   * @param  {String} situation The situation (location) for the task.
   * @return {String} The name needed for the icon class.
   */
  function iconForSituation(situation) {
    switch(situation) {
      case "onsite":
        return "plane"
      case "home":
        return "home"
      case "office":
        return "building-o"
      default:
        return "question"
    }
  }
})
